import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Col, Form, Input, Row, Select, Spin, Tag, Upload } from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getUsers } from 'app/modules/administration/user-management/user-management.reducer';
import { createEntity, getEntity, updateEntity } from './annonce.reducer';
import { getEntities as getGroupes } from 'app/entities/groupe/groupe.reducer';
import { getEntities as getNiveaux } from 'app/entities/niveau/niveau.reducer';
import moment from 'moment';

const { Option } = Select;

export const AnnonceUpdate = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const users = useAppSelector(state => state.userManagement.users);
  const groupes = useAppSelector(state => state.groupe.entities);
  const niveaux = useAppSelector(state => state.niveau.entities);
  const annonceEntity = useAppSelector(state => state.annonce.entity);
  const loading = useAppSelector(state => state.annonce.loading);
  const updating = useAppSelector(state => state.annonce.updating);
  const updateSuccess = useAppSelector(state => state.annonce.updateSuccess);

  const [imageUrlType, setImageUrlType] = useState<string>();
  const [imageUrl, setImageUrl] = useState<string>();
  const [scope, setScope] = useState<string>('tout le monde'); // Default scope
  const [scopeId, setScopeId] = useState<string | number>();
  const [isToutLeMonde, setIsToutLeMonde] = useState<boolean>(true); // Default flag

  const handleClose = () => {
    navigate('/annonce');
  };

  useEffect(() => {
    if (!isNew) {
      dispatch(getEntity(id));
    }
    dispatch(getUsers({}));
    dispatch(getGroupes({}));
    dispatch(getNiveaux({}));
  }, [dispatch, id, isNew]);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  useEffect(() => {
    if (annonceEntity && !isNew) {
      setImageUrlType(annonceEntity.imageContentType);
      if (annonceEntity.image) {
        setImageUrl(annonceEntity.image.includes('data:image/jpeg;base64') ? annonceEntity.image.split(',')[1] : annonceEntity.image);
      }

      if (annonceEntity.groupe) {
        setScope('groupe');
        setScopeId(annonceEntity.groupe.id);
        setIsToutLeMonde(false);
      } else if (annonceEntity.niveau) {
        setScope('niveau');
        setScopeId(annonceEntity.niveau.id);
        setIsToutLeMonde(false);
      } else if (annonceEntity.user) {
        setScope('utilisateur');
        setIsToutLeMonde(false);
      } else {
        setScope('tout le monde');
        setIsToutLeMonde(true);
      }
    }
  }, [annonceEntity, isNew]);

  const saveEntity = values => {
    const entity = {
      ...annonceEntity,
      ...values,
      id: isNew ? null : annonceEntity.id,
      dateEnvoi: moment(values.dateEnvoi),
      image: imageUrl ? imageUrl.replace('data:image/jpeg;base64,', '') : imageUrl,
      imageContentType: imageUrlType,
      users: scope === 'utilisateur' ? [users.find(it => it.id.toString() === values.user?.toString())] : null,
      groupe: scope === 'groupe' ? groupes.find(it => it.id.toString() === values.groupOrLevel?.toString()) : null,
      niveau: scope === 'niveau' ? niveaux.find(it => it.id.toString() === values.groupOrLevel?.toString()) : null,
      isToutLeMonde,
    };

    if (isNew) {
      dispatch(createEntity(entity)).then(() => handleClose());
    } else {
      dispatch(updateEntity(entity)).then(() => handleClose());
    }
  };

  const defaultValues = () =>
    isNew
      ? {}
      : {
          ...annonceEntity,
          dateEnvoi: moment(annonceEntity.dateEnvoi),
          image: imageUrl ? imageUrl.replace('data:image/jpeg;base64,', '') : imageUrl,
          imageContentType: imageUrlType,
          user: annonceEntity?.user?.id,
          groupe: annonceEntity?.groupe?.id,
          niveau: annonceEntity?.niveau?.id,
          isToutLeMonde: annonceEntity?.isToutLeMonde,
        };

  const uploadButton = (
    <button style={{ border: 0, background: 'none' }} type="button">
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Charger une image</div>
    </button>
  );

  const getBase64 = (img, callback: (url: string) => void) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result as string));
    reader.readAsDataURL(img);
  };

  const handleChange = info => {
    if (info.file.status === 'done') {
      getBase64(info.file.originFileObj, url => {
        setImageUrl(url.split(',')[1]);
        setImageUrlType(info.file.type);
      });
    }
  };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <Tag bordered={false} style={{ padding: 15 }} className={'capitalize'} color="orange">
            Créer une Annonce
          </Tag>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="12">
          {loading ? (
            <Spin size="large" />
          ) : (
            <Form initialValues={defaultValues()} onFinish={saveEntity} layout="horizontal">
              {!isNew && (
                <Form.Item name="id" label="ID" rules={[{ required: true, message: 'ID requis' }]}>
                  <Input readOnly />
                </Form.Item>
              )}

              <Form.Item name="scope" label="Cible" rules={[{ required: true, message: 'Veuillez sélectionner une cible !' }]}>
                <Select
                  onChange={value => {
                    setScope(value);
                    setIsToutLeMonde(value === 'tout le monde');
                  }}
                >
                  <Option value="tout le monde">Tout le monde</Option>
                  <Option value="utilisateur">Utilisateur</Option>
                  <Option value="groupe">Groupe</Option>
                  <Option value="niveau">Niveau</Option>
                </Select>
              </Form.Item>
              {scope === 'utilisateur' && (
                <Form.Item name="user" label="Utilisateur" rules={[{ required: true, message: 'Veuillez sélectionner un utilisateur !' }]}>
                  <Select
                    allowClear
                    showSearch
                    filterOption={(input, option) => {
                      const text = option?.label || option?.children;
                      return text?.toString().toLowerCase().includes(input.toLowerCase());
                    }}
                  >
                    {users.map(otherEntity => (
                      <Option key={otherEntity.id} value={otherEntity.id}>
                        {otherEntity.firstName} {otherEntity.lastName}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              )}
              {scope === 'groupe' && (
                <Form.Item name="groupOrLevel" label="Groupe" rules={[{ required: true, message: 'Veuillez sélectionner un groupe !' }]}>
                  <Select
                    allowClear
                    showSearch
                    filterOption={(input, option) => {
                      const text = option?.label || option?.children;
                      return text?.toString().toLowerCase().includes(input.toLowerCase());
                    }}
                  >
                    {groupes.map(otherEntity => (
                      <Option key={otherEntity.id} value={otherEntity.id}>
                        {otherEntity.nom}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              )}
              {scope === 'niveau' && (
                <Form.Item name="groupOrLevel" label="Niveau" rules={[{ required: true, message: 'Veuillez sélectionner un niveau !' }]}>
                  <Select
                    allowClear
                    showSearch
                    filterOption={(input, option) => {
                      const text = option?.label || option?.children;
                      return text?.toString().toLowerCase().includes(input.toLowerCase());
                    }}
                  >
                    {niveaux.map(otherEntity => (
                      <Option key={otherEntity.id} value={otherEntity.id}>
                        {otherEntity.libelle}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              )}
              <Form.Item name="contenu" label="Contenu">
                <Input.TextArea />
              </Form.Item>
              <Form.Item
                name="lien"
                label="Lien"
                rules={[
                  {
                    type: 'url',
                    message: 'Veuillez entrer une URL valide !',
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item hasFeedback name="image" label="Image">
                <Upload
                  customRequest={({ onSuccess }) => onSuccess('ok')}
                  name="image"
                  listType="picture-card"
                  className="avatar-uploader"
                  showUploadList={false}
                  onChange={handleChange}
                >
                  {imageUrl ? <img src={`data:image/jpeg;base64,${imageUrl}`} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
                </Upload>
              </Form.Item>

              <div className="d-flex justify-content-end">
                <Button onClick={handleClose} type="default">
                  <span>Retour</span>
                </Button>
                &nbsp;
                <Button type="primary" htmlType="submit" loading={updating}>
                  <span>Enregistrer</span>
                </Button>
              </div>
            </Form>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default AnnonceUpdate;
